/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.mt30 {
  margin-top: 30px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}